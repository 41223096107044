
import { Options, Vue } from "vue-class-component";

@Options({
  props: {
    count: Number,
  },
})
export default class Stepper extends Vue {
  count!: number;
}
