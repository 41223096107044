
import { Options, Vue } from "vue-class-component";

type Mode = "full" | "overlay";

@Options({
  props: {
    iframeURL: String,
    mode: String,
    onClickHandler: Function,
  },
})
export default class IframeInModal extends Vue {
  iframeURL!: string;
  mode!: Mode;
  onClickHandler?: () => void;
}
