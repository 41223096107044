
import { Options, Vue } from "vue-class-component";

import Stepper from "../presenters/Stepper.vue";
import OutlinedInput from "../presenters/formElements/outlinedInput.vue";
import OutlinedSelect from "../presenters/formElements/outlinedSelect.vue";
import OutlinedRadio from "../presenters/formElements/outlinedRadio.vue";
import CommonButton from "../presenters/buttons/commonButton.vue";
import { Core as YubinBangoCore } from "yubinbango-core";

import {
  getOnlineClose,
  getOnlineWebStatus,
  postOnlineForm,
  OnlineExaminationContactStatus,
  OnlineFormVueModels,
  getOssUserProfile,
} from "../repositories/api";

interface selectOption {
  value: string;
  name: string;
}

@Options({
  components: {
    Stepper,
    OutlinedInput,
    OutlinedSelect,
    CommonButton,
    OutlinedRadio,
  },
})
export default class Contact extends Vue {
  private contractCode = 863738;

  private stepCount = 1;
  private isClose = false;

  private postStatus = "";
  private consultClinic = "";
  private selectedOnlineBranchId = 1;
  private onlineExaminationContactStatus: OnlineExaminationContactStatus =
    "enable";

  // 診断情報送信後に取得するユーザー固有のtoken
  private homeVisitToken = "";

  private fastdoctorJpBaseURL = process.env.VUE_APP_FASTDOCTOR_JP_URL;
  private apiBaseURL = process.env.VUE_APP_API_BASE_URL;

  private formVueModels: OnlineFormVueModels = {
    nameSei: {
      value: "",
      name: "氏名(姓)",
      errorMessage: "",
    },
    nameMei: {
      value: "",
      name: "氏名(名)",
      errorMessage: "",
    },
    tel: {
      value: "",
      name: "電話番号",
      errorMessage: "",
    },
    symptom: {
      value: "",
      name: "最もお辛い症状",
      errorMessage: "",
    },
    zipCode: {
      value: "",
      name: "郵便番号",
      errorMessage: "",
    },
    prefecture: {
      value: "",
      name: "都道府県",
      errorMessage: "",
    },
    locality: {
      value: "",
      name: "市・区",
      errorMessage: "",
    },
    address1: {
      value: "",
      name: "町名・番地",
      errorMessage: "",
    },
    address2: {
      value: "",
      name: "建物名・部屋番号",
      errorMessage: "",
    },
  };

  private symptomOptions: selectOption[] = [
    { value: "高熱", name: "高熱" },
    { value: "咳・喉の痛み", name: "咳・喉の痛み" },
    { value: "鼻水", name: "鼻水" },
    { value: "頭痛", name: "頭痛" },
    { value: "嘔吐", name: "嘔吐" },
    { value: "腹痛・下痢", name: "腹痛・下痢" },
    { value: "胸痛", name: "胸痛" },
    { value: "腰痛・背部痛", name: "腰痛・背部痛" },
    { value: "切り傷・刺し傷", name: "切り傷・刺し傷" },
    { value: "打撲・関節痛", name: "打撲・関節痛" },
    { value: "皮膚症状", name: "皮膚症状" },
    { value: "その他の症状", name: "その他の症状" },
  ];

  private prefecturesOptions: selectOption[] = [
    { value: "東京都", name: "東京都" },
    { value: "神奈川県", name: "神奈川県" },
    { value: "千葉県", name: "千葉県" },
    { value: "埼玉県", name: "埼玉県" },
    { value: "愛知県", name: "愛知県" },
    { value: "大阪府", name: "大阪府" },
    { value: "兵庫県", name: "兵庫県" },
    { value: "京都府", name: "京都府" },
    { value: "奈良県", name: "奈良県" },
    { value: "福岡県", name: "福岡県" },
  ];

  public async created(): Promise<void> {
    const onlineClose = await getOnlineClose();
    const onlineWebStatus = await getOnlineWebStatus();
    this.selectedOnlineBranchId = onlineWebStatus.selectedOnlineBranchId;
    if (
      onlineWebStatus.onlineExaminationContactStatus === "disable" ||
      onlineClose
    ) {
      this.isClose = true;
      return;
    }

    const ossUserProfile = await getOssUserProfile();
    if (ossUserProfile.status === 200) {
      this.formVueModels.nameSei.value = ossUserProfile.nameSei;
      this.formVueModels.nameMei.value = ossUserProfile.nameMei;
      this.formVueModels.tel.value = ossUserProfile.tel;
      this.formVueModels.zipCode.value = ossUserProfile.zipCode;
      this.formVueModels.address1.value = ossUserProfile.address1;
      this.formVueModels.address2.value = ossUserProfile.address2;
      this.getAddressFromZipCode();
    }
  }

  private getAddressFromZipCode(): void {
    new YubinBangoCore(
      this.formVueModels.zipCode.value,
      (addr: {
        extended: string;
        locality: string;
        region: string;
        region_id: number;
        street: string;
      }) => {
        this.formVueModels.prefecture.value = addr.region;
        this.formVueModels.locality.value = addr.locality;
        // 町名以下が既に入力されていて部分一致する場合、必要箇所だけ残す
        // 例: 新宿区内藤町1-110 > 内藤町1-110
        if (this.formVueModels.address1.value.split(addr.street).length > 1) {
          this.formVueModels.address1.value =
            addr.street +
            this.formVueModels.address1.value.split(addr.street)[1];
        } else {
          this.formVueModels.address1.value = addr.street;
        }
      }
    );
  }

  private goOnlinePage(): void {
    this.$router.push("/");
  }

  private toConfirmation(): void {
    this.resetValidationInput();
    if (this.validationInput()) {
      this.nextStep();
    } else {
      this.scrollTop();
    }
  }

  private resetValidationInput(): void {
    for (const key of Object.keys(this.formVueModels)) {
      this.formVueModels[key].errorMessage = "";
    }
  }

  private validationInput(): boolean {
    const requiredItems = [
      this.formVueModels.nameSei,
      this.formVueModels.nameMei,
      this.formVueModels.tel,
      this.formVueModels.symptom,
      this.formVueModels.zipCode,
      this.formVueModels.prefecture,
      this.formVueModels.locality,
      this.formVueModels.address1,
    ];
    let isErr = false;
    requiredItems.forEach((requiredItem) => {
      if (requiredItem.value === "") {
        requiredItem.errorMessage = `${requiredItem.name}が未入力です`;
        isErr = true;
      }
    });
    return !isErr;
  }

  private prevStep(): void {
    this.scrollTop();
    this.stepCount--;
  }

  private nextStep(): void {
    this.scrollTop();
    this.stepCount++;
  }

  private scrollTop(): void {
    window.scrollTo({
      top: 0,
    });
  }

  public async postForm(): Promise<void> {
    const postResult = await postOnlineForm(
      this.formVueModels,
      this.selectedOnlineBranchId,
      this.contractCode
    );

    this.postStatus = postResult.status;
    this.homeVisitToken = postResult.homeVisitToken;

    this.nextStep();
  }

  public goFastdoctorUserPage(): void {
    window.location.href = `${this.apiBaseURL}/user_site/medical_examinations/${this.homeVisitToken}`;
  }
}
