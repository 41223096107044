
import { Options, Vue } from "vue-class-component";

@Options({
  props: {
    placeholder: String,
    name: String,
    errorMessage: String,
    value: String,
  },
  emits: ["update:value"],
})
export default class OutlinedInput extends Vue {
  placeholder!: string;
  name!: string;
  errorMessage!: string;
  value!: string;
}
