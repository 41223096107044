import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "flex text-left" }
const _hoisted_2 = ["name", "value", "id"]
const _hoisted_3 = ["for"]
const _hoisted_4 = {
  key: 0,
  class: "text-red-400 my-1 text-left"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", null, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (option, i) => {
          return (_openBlock(), _createElementBlock("div", {
            class: "form-check",
            key: i
          }, [
            _createElementVNode("input", {
              class: "h-4 w-4 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer",
              type: "radio",
              name: _ctx.name,
              value: option.value,
              id: option.value,
              onChange: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('update:value', $event.target.value)))
            }, null, 40, _hoisted_2),
            _createElementVNode("label", {
              class: "form-check-label inline-block text-gray-800",
              for: option.value
            }, _toDisplayString(option.name), 9, _hoisted_3)
          ]))
        }), 128))
      ])
    ]),
    (_ctx.errorMessage !== undefined && _ctx.errorMessage !== '')
      ? (_openBlock(), _createElementBlock("p", _hoisted_4, _toDisplayString(_ctx.errorMessage), 1))
      : _createCommentVNode("", true)
  ], 64))
}