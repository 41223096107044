
import { Options, Vue } from "vue-class-component";

interface radioOption {
  value: string;
  name: string;
}

@Options({
  props: {
    name: String,
    errorMessage: String,
    options: [],
    value: String,
  },
  emits: ["update:value"],
})
export default class OutlinedRadio extends Vue {
  name!: string;
  errorMessage!: string;
  options!: radioOption[];
  value!: string;
}
