import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, vShow as _vShow, withDirectives as _withDirectives, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "absolute w-full p-2 text-center",
  style: {"z-index":"9999","bottom":"0px","background-color":"rgba(0, 0, 0, 0.4)"}
}
const _hoisted_2 = { class: "md:mx-40" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_IframeInModal = _resolveComponent("IframeInModal")!
  const _component_CommonButton = _resolveComponent("CommonButton")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_IframeInModal, {
      iframeURL: _ctx.fastdoctorJpIframeURL,
      mode: "full"
    }, null, 8, ["iframeURL"]),
    _withDirectives(_createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_CommonButton, {
          size: "medium",
          title: "オンライン診療を申込む",
          color: "green",
          onClickHandler: _ctx.goOnlineContact
        }, null, 8, ["onClickHandler"])
      ])
    ], 512), [
      [_vShow, _ctx.isShowGoContactButton]
    ])
  ], 64))
}