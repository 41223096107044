<template>
  <header class="globalHeaderSP js-header">
    <div class="globalHeaderSP__logo">
      <div class="globalHeaderSP__logoWrap">
        <a href="https://fastdoctor.jp"
          ><img
            class="globalHeaderSP__logoImage"
            src="https://fastdoctor.jp/wp-content/themes/fastdoctor-common/assets/img/logo_only.svg"
            alt="ファストドクター"
            width="238"
            height="29"
        /></a>
      </div>
      <div class="globalHeaderSP__logoSub">
        【救急総合窓口】ファストドクター
      </div>
    </div>
  </header>
  <div class="pt-10">
    <router-view />
  </div>
</template>

<style>
.globalHeaderSP {
  position: fixed;
  z-index: 1000;
  width: 100%;
  height: 44px;
  border-bottom: 1px solid #e0dfde;
  background-color: #fff;
  top: 0;
  left: 0;
  display: grid;
  grid: "logo lang navi" auto / 1fr auto auto;
  padding: 6px 15px;
}

.globalHeaderSP__logo {
  grid-area: logo;
}

.globalHeaderSP__logoImage {
  width: 150px;
  height: 17px;
  display: block;
  margin-bottom: 3px;
}

.globalHeaderSP__logoSub {
  font-size: 11px;
  line-height: 1;
  color: #345678;
}
</style>
