import axios from "axios";

const apiBaseURL = `${process.env.VUE_APP_API_BASE_URL}/api`;

export type OnlineExaminationContactStatus = "enable" | "disable";

interface OnlineFormVueModel {
  value: string;
  name: string;
  errorMessage: string;
}

export interface OnlineFormVueModels {
  nameSei: OnlineFormVueModel;
  nameMei: OnlineFormVueModel;
  tel: OnlineFormVueModel;
  symptom: OnlineFormVueModel;
  zipCode: OnlineFormVueModel;
  prefecture: OnlineFormVueModel;
  locality: OnlineFormVueModel;
  address1: OnlineFormVueModel;
  address2: OnlineFormVueModel;
  [key: string]: OnlineFormVueModel;
}

export const getAreaSupportStatus = (
  prefectureName: string,
  cityName: string,
  address1: string
): Promise<string> => {
  return new Promise((resolve) => {
    axios
      .post(`${apiBaseURL}/areas/check.json`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer dummyToken",
        },
        data: {
          address: {
            prefecture_name: prefectureName,
            city_name: cityName,
            address1: address1,
          },
        },
      })
      .then(async (response: any) => {
        let status = "noMatch";
        if (!response || !response.data) {
          status = "error";
        }
        if (response.data.available === true) {
          status = "match";
        }
        resolve(status);
      })
      .catch(function (error) {
        console.log("ERROR!! occurred in Backend.");
        resolve("error");
      });
  });
};

export const getOnlineClose = (): Promise<boolean> => {
  return new Promise((resolve) => {
    axios
      .get(`${apiBaseURL}/receptions/online_examinations.json`)
      .then(async (response: any) => {
        resolve(response.data.reception.closed);
      })
      .catch(function (error) {
        console.log("ERROR!! occurred in Backend.");
        resolve(true);
      });
  });
};

export const getOnlineWebStatus = (): Promise<{
  consultClinic: string;
  selectedOnlineBranchId: number;
  onlineExaminationContactStatus: OnlineExaminationContactStatus;
}> => {
  return new Promise((resolve) => {
    axios
      .get(`${apiBaseURL}/online_web_statuses.json`)
      .then(async (response: any) => {
        resolve({
          consultClinic: response.data.online[0].web_status.consult_clinic,
          selectedOnlineBranchId:
            response.data.online[0].web_status.consult_clinic.split("_")[0] ||
            1,
          onlineExaminationContactStatus:
            response.data.online[0].web_status
              .online_examination_contact_status,
        });
      })
      .catch(function (error) {
        console.log("ERROR!! occurred in Backend.");
        resolve({
          consultClinic: "",
          selectedOnlineBranchId: 1,
          onlineExaminationContactStatus: "disable",
        });
      });
  });
};

export const getOssUserProfile = (): Promise<{
  status: number;
  nameSei: string;
  nameMei: string;
  tel: string;
  zipCode: string;
  address1: string;
  address2: string;
}> => {
  const postData = {
    // os, browser, lang, timezone, resolutionを取得して送ってください
    client_info: {
      os: navigator.platform, // MacIntel
      browser: navigator.appCodeName, // Mozilla
      lang: navigator.language, // ja
      timezone: window.Intl.DateTimeFormat().resolvedOptions().timeZone, // Asia/Tokyo
      resolution: `${window.screen.width}x${window.screen.height}`, // 1920x1080
    },
  };

  return new Promise((resolve) => {
    axios
      .post(`${process.env.VUE_APP_OSAKA_GAS_GATEWAY_BASE_URL}/api/osaka-user-info`, postData, {
        headers: {
          "Content-Type": "application/json",
        },
        // NOTE: cookieが必要なのでwithCredentialsでお願いします
        withCredentials: true,
      })
      .then(async (response: any) => {
        resolve({
          status: response.status,
          nameSei: response.data.osaka_user.lastNameKana,
          nameMei: response.data.osaka_user.firstNameKana,
          tel: response.data.osaka_user.mobileNumber,
          zipCode: response.data.osaka_user.zip,
          address1: response.data.osaka_user.address,
          address2: response.data.osaka_user.buildingName,
        });
      })
      .catch(function (error) {
        let status = 500;
        if(error.response){
          status = error.response.status;
        }
        if (status === 401 || status === 422) {
          // 401: cookieが存在しない（大阪ガス側のページから来ていないか、cookie有効期限切れ）
          // 422: トークン有効期限切れ
          // スマイリンクの対応サイトへ戻す(本番: https://smilink.osakagas.co.jp)
          window.location.href = process.env.VUE_APP_OSAKA_GAS_SMILINK_URL;
        }
        resolve({
          status: status,
          nameSei: "",
          nameMei: "",
          tel: "",
          zipCode: "",
          address1: "",
          address2: "",
        });
      });
  });
}

export const postOnlineForm = (
  onlineFormInput: OnlineFormVueModels,
  selectedOnlineBranchId: number,
  contractCode: number
): Promise<{
  status: string;
  homeVisitToken: string;
}> => {
  return new Promise((resolve) => {
    axios
      .post(`${apiBaseURL}/receptions.json`, {
        patient: {
          first_name: "",
          last_name: "",
          first_kana_name: onlineFormInput.nameMei.value,
          last_kana_name: onlineFormInput.nameSei.value,
          birthday: "--",
          gender: "",
          tel: onlineFormInput.tel.value,
          email: null,
          address: {
            zip_code: onlineFormInput.zipCode.value,
            prefecture_name: onlineFormInput.prefecture.value,
            city_name: onlineFormInput.locality.value,
            address1: onlineFormInput.address1.value,
            address2: onlineFormInput.address2.value,
          },
        },
        reception: {
          reservation_method: "fast_doctor_site",
          desired_time: "",
          symptom_description: "",
          outpatient: "online_examination",
          selected_online_branch_id: selectedOnlineBranchId,
          request_reply_method: null,
          tie_up_partner: null,
          tie_up_support_instruction: null,
          remarks: null,
        },
        medical_examination: {
          contract_code: contractCode,
        },
      })
      .then(async (response: any) => {
        resolve({
          status: response.data.reception.state,
          homeVisitToken: response.data.reception.home_visit_token,
        });
      })
      .catch(function (error) {
        console.log("ERROR!! occurred in Backend.");
        resolve({
          status: "error",
          homeVisitToken: "",
        });
      });
  });
};
