
import { Options, Vue } from "vue-class-component";

type Size = "small" | "medium";
type Color = "primary" | "secondary" | "green";

@Options({
  props: {
    title: String,
    size: String,
    color: String,
    onClickHandler: Function,
  },
})
export default class CommonButton extends Vue {
  title!: string;
  size!: Size;
  color!: Color;
  onClickHandler?: () => void;

  private colorClass(color: Color) {
    switch (color) {
      case "primary":
        return "bg-blue-800 hover:bg-blue-400 text-white";
      case "secondary":
        return "bg-gray-200 hover:bg-gray-400 text-gray-800";
      case "green":
        return "bg-green-400 hover:bg-green-200 text-white";
      default:
        return "bg-gray-200 hover:bg-gray-400 text-gray-800";
    }
  }
}
