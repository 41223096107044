import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = ["placeholder", "name", "autocomplete", "value"]
const _hoisted_2 = {
  key: 0,
  class: "text-red-400 my-1 text-left"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("input", {
      type: "text",
      class: _normalizeClass(["appearance-none border rounded w-full py-2 px-3 leading-tight border-gray-400", 
        _ctx.errorMessage !== undefined && _ctx.errorMessage !== ''
          ? 'border-red-400'
          : ''
      ]),
      placeholder: this.placeholder,
      name: this.name,
      autocomplete: this.name,
      value: this.value,
      onInput: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('update:value', $event.target.value)))
    }, null, 42, _hoisted_1),
    (_ctx.errorMessage !== undefined && _ctx.errorMessage !== '')
      ? (_openBlock(), _createElementBlock("p", _hoisted_2, _toDisplayString(_ctx.errorMessage), 1))
      : _createCommentVNode("", true)
  ]))
}