import { toDisplayString as _toDisplayString, withModifiers as _withModifiers, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("button", {
    class: _normalizeClass(["font-bold px-4 rounded w-full", [_ctx.colorClass(_ctx.color), _ctx.size === 'medium' ? 'py-4' : 'py-2']]),
    type: "button",
    onClick: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.onClickHandler && _ctx.onClickHandler(...args)), ["stop"]))
  }, _toDisplayString(_ctx.title), 3))
}