
import { Options, Vue } from "vue-class-component";
import IframeInModal from "../presenters/iframeInModal.vue";
import CommonButton from "../presenters/buttons/commonButton.vue";

import { getOssUserProfile } from "../repositories/api";

@Options({
  components: {
    IframeInModal,
    CommonButton,
  },
})
export default class Home extends Vue {
  private isShowGoContactButton = false;
  private fastdoctorJpIframeURL = process.env.VUE_APP_FASTDOCTOR_JP_IFRAME_URL;

  public async mounted(): Promise<void> {
    // セッションの有無を確認するがレスポンスは利用しない
    await getOssUserProfile();
    this.isShowGoContactButton = true;
  }

  private goOnlineContact(): void {
    this.$router.push("/contact");
  }
}
